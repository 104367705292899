function utcValues(date) {
  if (!date) return new Date(Date.UTC('2020', '11', '25', '06', '00', '00'))
  const year = date.slice(0, 4)
  const month = (date.slice(5, 7) * 1) - 1
  const day = date.slice(8, 10)
  const hour = date.slice(11, 13)
  const minute = date.slice(14, 16)
  const second = date.slice(17, 19)
  const utcDate = new Date(Date.UTC(year, month, day, hour, minute, second))
  return utcDate
}

export default utcValues
