import handlePromise from 'utils/handle-promise'

const hostname = process.env.API_HOST || 'https://api.learning.rest'

export async function registerUserService(payload) {
  try {
    const response = await fetch(`${hostname}/api/v1/auth/register/`, {
      method: 'POST',
      body: payload,
    })
    const data = await response.json()
    if (data?.email?.code === 'auth.EmailIsAlreadyUsed') {
      return {
        error: true,
        message: 'Ha ocurrido un error al crear el usuario :(',
      }
    }
    return {
      message: 'Te hemos enviado un email para que confirmes tu correo',
    }
  } catch (error) {
    console.error(error)
    return {
      error: true,
      message: 'Ha ocurrido un error al crear el usuario :(',
    }
  }
}

export async function createCredentialSession(payload) {
  const data = await handlePromise('/api/v1/auth/login/', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })
  return data
}

export async function createSessionWithGoogle({ data, origin }) {
  const originQuery = origin ? `?origin=${origin}` : ''
  const googleSession = await handlePromise(`/api/v1/auth/google-login/${originQuery}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
  return googleSession
}

export async function createSessionWithGigthub({ data, origin }) {
  try {
    const originQuery = origin ? `?origin=${origin}` : ''
    const response = await fetch(`${hostname}/api/v1/auth/github-login/${originQuery}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
    const githubSession = await response.json()
    return githubSession
  } catch (e) {
    console.error(e)
    return { statusCode: 503 }
  }
}

export async function recoverPasswordService(email) {
  const data = await handlePromise('/api/v1/auth/reset-password/', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ user: email }),
  })
  return data
}

export async function confirmAccountService({ token, payload }) {
  const res = await fetch(`${hostname}/api/v1/activate-account/${token}/`, {
    method: 'POST',
    body: payload,
  })
  if (!res.ok) {
    throw new Error('El codigo de activacion es incorrecto')
  }
  const data = await res.json()
  return data
}
