import { useQuery } from 'react-query'
import { useRouter } from 'next/router'
import {
  getCountryService, getFinancialPlansService, getPaymentConfigService, getPaymentStatusService,
} from 'services/payment'
import { flag, name } from 'country-emoji'
import useSession from './session'

export function useRealCountryCode() {
  return useQuery(
    'REAL_COUNTRY_CODE', getCountryService,
    {
      placeholderData: { countryCode: 'MX' },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnMount: false,
    },
  )
}

export function useCountryCode() {
  const { query: { country_code } } = useRouter()
  return useQuery(
    ['GET_COUNTRY_CODE', country_code], getCountryService,
    {
      placeholderData: { countryCode: 'MX' },
      initialData: () => country_code && {
        countryCode: country_code,
        country: name(flag(country_code)),
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Infinity,
    },
  )
}

export async function prefetchPaymentStatus({ queryClient, course, serverToken }) {
  const token = serverToken || ''
  await queryClient.prefetchQuery(
    ['GET_PAYMET_STATUS', course, token],
    () => getPaymentStatusService({
      course_slug: course,
      token,
    }),
  )
}

export function usePaymentStatus(courseSlug) {
  const router = useRouter()
  const { query } = router
  const course = courseSlug || query.course || query.course_slug
  const { token } = useSession()
  const queryStatus = useQuery(
    ['GET_PAYMET_STATUS', course, token],
    () => getPaymentStatusService({
      course_slug: course,
      token,
    }),
    {
      enabled: !!token,
      initialData: {
        course: '',
        user: '',
        has_access: false,
        expiration: null,
      },
      refetchOnWindowFocus: false,
    },
  )
  if (!token) {
    return {
      data: {
        course: '',
        user: '',
        has_access: false,
        expiration: null,
      },
    }
  }
  return { ...queryStatus, course }
}

export async function prefetchPaymentConfig(queryClient, countryCode) {
  await queryClient.prefetchQuery(
    'GET_PAYMENT_CONFIG',
    () => getPaymentConfigService({ countryCode }),
  )
}

export function usePaymentConfig() {
  const { data: { countryCode } } = useCountryCode()
  return useQuery(
    'GET_PAYMENT_CONFIG',
    () => getPaymentConfigService({ countryCode }),
  )
}

export function useFinancialPlans({ amount, currency_code }) {
  return useQuery(
    ['GET_FINANCIAL_PLANS', amount, currency_code],
    () => getFinancialPlansService({ amount, currency_code }),
    {
      enabled: !!amount,
      staleTime: Infinity,
    },
  )
}
