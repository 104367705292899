import handlePromise from 'utils/handle-promise'

export async function registerAddPaymentInfoService({ token, payload }) {
  const data = await handlePromise('/api/v1/growth/payment-info/', {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })
  return data
}

export async function getCampaingService({ token }) {
  const data = await handlePromise('/api/v1/campaign/', {
    headers: {
      Authorization: token,
    },
    method: 'GET',
  })
  return data
}

export async function getCustomCampaignService({ token }) {
  const data = await handlePromise('/api/v1/me/campaign/', {
    headers: {
      Authorization: token,
    },
    method: 'GET',
  })
  return data
}
