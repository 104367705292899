import handlePromise from 'utils/handle-promise'

const hostname = process.env.API_HOST || 'https://api.learning.rest'

export async function getSubscriptionStatusService(suscriptionId) {
  const url = `/api/v1/subscriptions/${suscriptionId}`
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}

export async function retrySubscriptionPaymentService({ uuid, token }) {
  const url = `/api/v1/subscriptions/${uuid}/`
  const data = await handlePromise(url, {
    method: 'PUT',
    headers: { Authorization: token },
  })
  return data
}

export async function restartSubscriptionService({ uuid, token }) {
  const url = `/api/v1/subscriptions/${uuid}/`
  const data = await handlePromise(url, {
    method: 'POST',
    headers: { Authorization: token },
  })
  return data
}

export async function changePaymentMethodService({ payload, token, countryCode }) {
  const url = `/api/v1/payment-methods/?country_code=${countryCode}`
  const data = await handlePromise(url, {
    method: 'PUT',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  return data
}

export async function getSubscriptionStatus({
  token, product, recurring, res, req,
}) {
  const productQuery = product ? `?product=${product}` : ''
  const recurringQuery = product ? `&recurring=${recurring}` : ''
  const url = `${hostname}/api/v1/subscriptions/${productQuery}${recurringQuery}`
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: token },
  }, res, req)
  const data = await response.json()
  return data
}

export async function getSubscriptionPlansService({ countryCode, token, couponCode }) {
  const couponQuery = couponCode ? `&coupon_code=${couponCode}` : ''
  const url = `/api/v1/subscription-plans/?country_code=${countryCode}${couponQuery}`
  const data = await handlePromise(url, {
    headers: {
      Authorization: token || null,
      'Content-Type': 'application/json',
    },
  })
  return data
}

export async function createSubscriptionService({
  token, countryCode, billingDetails, couponCode, version, referralSlug,
}) {
  try {
    const captcha = await grecaptcha.execute('6LelzYQaAAAAANrs-ogCFdVq2_o-6YAmiDNgw-mL', { action: 'submit' })
    const captchaQuery = `&g-recaptcha-response=${captcha}`
    const couponQuery = couponCode ? `&coupon_code=${couponCode}` : ''
    const referralQuery = referralSlug ? `&referral_from=${referralSlug}` : ''
    const url = `${hostname}/api/${version}/subscriptions/?country_code=${countryCode}${captchaQuery}${couponQuery}${referralQuery}`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: token || null,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(billingDetails),
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return { error: true }
  }
}

export async function addPartnerService({ uuid, token, partnerData }) {
  const url = `/api/v1/subscriptions/${uuid}/partner/`
  const data = await handlePromise(url, {
    method: 'PUT',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(partnerData),
  })
  return data
}

export async function cancelSubscriptionService({ token, uuid, feedback, restart }) {
  const r = restart ? '?restart=true' : ''
  const url = `/api/v1/subscriptions/${uuid}/${r}`
  const data = await handlePromise(url, {
    method: 'DELETE',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ feedback }),
  })
  return data
}

export async function pauseSubscriptionService({ token, uuid, months, restart }) {
  const m = months ? `?months=${months}` : ''
  const url = `/api/v1/subscriptions/${uuid}/pause/${m}`
  const data = await handlePromise(url, {
    method: 'PUT',
    headers: { Authorization: token },
  })
  return data
}

export async function subscriptionCancellationService({ token, uuid }) {
  const url = `/api/v1/subscriptions/${uuid}/discount/`
  const data = await handlePromise(url, {
    method: 'PUT',
    headers: { Authorization: token },
  })
  return data
}
