/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { MdClose } from 'react-icons/md'
import Wrapper from 'components/common/wrapper'
import { P, Typography } from 'components/common/typography'
import useDiff from 'hooks/use-diff'
import { useCampaign } from 'querys/growth'
import DiscountTimer from 'common/banner/discount-timer'
import { useSubscriptionStatus } from 'querys/subscriptions'
import useSession from 'querys/session'
import FirstOffer from 'class/first-offer'

const SimpleCountDownStyled = styled.div`
  padding: .5rem 1rem;
  position: relative;
  top: 0;
  font: var(--p2Regular);
  z-index: 4;
  background: var(--sky);
  min-height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &.discount-banner {
    background: #e9e700;
    color: var(--black);
  }
  .banner-spacer {
    display: none;
  }
  .coupon-expiration-description {
    display: none;
  }
  .countdow-numbers {
    margin: 8px;
  }
  .banner-countdown-container {
    width: 100%;
  }
  .banner-content-container {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .close-count {
    height: fit-content;
    position: absolute;
    right: 1rem;
  }
  @media(min-width: 1024px){
    position: static;
    padding: .5rem 80px;
    .close-count {
      right: 2.5rem;
    }
    .coupon-expiration-description {
      display: block;
    }
    .offer-ends-in {
      display: none;
    }
    .countdow-numbers {
      margin: 0 2px;
    }
    .banner-countdown-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .banner-container {
      display: flex;
      flex: 1;
      width: 100%;
    }
    .banner-spacer {
      margin: 0 8px;
      color: var(--secondary);
      display: block;
    }
  }
`

const DiscountEnd = styled.div`
  background: var(--black);
  color: var(--white);
  padding: 10px 0;
`

function CounDownBar({ setCountDownOpen }) {
  const { token } = useSession()
  const [open, setOpen] = useState(true)
  const [endDate, setEndDate] = useState(null)
  const { data, isError } = useCampaign()
  const { diff: diffInTime } = useDiff(endDate)
  const { data: { is_active }, isPlaceholderData } = useSubscriptionStatus({
    product: 'ALL', recurring: 'year',
  })

  let couponStatus = 0
  useEffect(() => {
    couponStatus = data?.coupon?.status
    setEndDate(data?.coupon?.expires_at)
  }, [data?.coupon])

  if (!data || isError) return null

  // If the banner has a discount, wait until validating if there is a subscription for the user
  if (!couponStatus && typeof a !== 'undefined') return null
  if (data?.coupon?.status === 'VALID' && token && isPlaceholderData) return null

  const {
    campaign_title: title,
    campaign_subtitle: subtitle,
    campaign_url: url,
    campaign_img: img,
    campaign_coupon,
    campaign_all_site: all,
    coupon,
  } = data
  // Handle Single Course Coupon
  // const course = pathname === '/cursos/[course]'
  // const courseInCuponCourses = coupon && coupon.courses.find((item) => item.slug === course)
  // const hasAccess = courseInCuponCourses?.has_access

  const visibleInThisPage = (!coupon && all) || (!is_active)
  const diff = diffInTime || (couponStatus === 'VALID' && !endDate)

  const hideCountDown = () => {
    if (setCountDownOpen) setCountDownOpen(false)
    setOpen(false)
  }

  if (!visibleInThisPage) return null

  // Simple Announcement
  if (title && !campaign_coupon && open) {
    return (
      <SimpleCountDownStyled>
        {url.charAt(0) === '/' ? (
          <Link href={url} className="banner-container">
            <Typography font="p2Bold" color="var(--black)">{title}</Typography>
            {subtitle && <span className="banner-spacer">|</span> }
            {subtitle && <Typography font="smallRegular">{subtitle}</Typography>}
          </Link>
        ) : (
          <a href={url} className="banner-container" target="_blank" rel="noopener noreferrer">
            <Typography font="p2Bold">{title}</Typography>
            {subtitle && <span className="banner-spacer">|</span> }
            {subtitle && <Typography font="smallRegular">{subtitle}</Typography>}
          </a>
        )}
        <div onClick={() => hideCountDown()} className="close-count" id="close-banner">
          <MdClose size={25} color="#3F69FF" />
        </div>
      </SimpleCountDownStyled>
    )
  }

  // Announcement with coupon
  if (open && diff) {
    return (
      <SimpleCountDownStyled className="discount-banner">
        <Link href={url} className="banner-countdown-container">
          <div className="banner-content-container">
            {/* {img ? <img width="54" height="54" src={img} alt={title} /> : null} */}
            <div>
              <Typography small="regularBold" large="headline6" color="var(--black)">{title}</Typography>
              {subtitle && <Typography font="p2Bold">{subtitle}</Typography>}
            </div>
          </div>
          <div>
            <DiscountTimer color="inherit" alignSmall="flex-end" align="start" discount={coupon} />
          </div>
        </Link>
        <div onClick={() => hideCountDown()} className="close-count" id="close-banner">
          <MdClose size={25} color="inherit" />
        </div>
      </SimpleCountDownStyled>
    )
  }

  if (!diff && couponStatus === 'VALID') {
    return (
      <DiscountEnd>
        <Wrapper>
          <P align="center" fontWeight="600">El descuento ya ha terminado :(</P>
        </Wrapper>
      </DiscountEnd>
    )
  }

  if (couponStatus !== 'VALID' || !title) {
    return (
      <FirstOffer />
    )
  }

  return null
}

export default CounDownBar
