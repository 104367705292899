import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useDiff from 'hooks/use-diff'
import { Typography } from 'common/typography'
import getCountDown from 'utils/count-down'

const CountDownStyled = styled.div`
  display: flex;
  font: ${({ fontSmall }) => `var(--${fontSmall})`};
  justify-content: ${({ align, alignSmall }) => alignSmall || align};
  margin: ${({ margin }) => margin || '0 2px'};
  .time {
    display: inline-block;
    text-align: center;
    min-width: ${({ smallWidth }) => smallWidth};
  }
  .separator {
    margin: 0 2px;
  }
  @media(min-width: 768px) {
    font: ${({ fontLarge }) => `var(--${fontLarge})`};
    min-width: 230px;
    justify-content: ${({ align }) => align};
    .time {
      display: inline-block;
      min-width: ${({ largeWidth }) => largeWidth};
    }
    .separator {
      margin: 0 4px;
    }
  }
`

const DiscountTimerStyled = styled.div`
  text-align: ${({ align, alignSmall }) => alignSmall || align};
  color: ${({ color }) => color};
  @media(max-width: 320px) {
    .offer-ends-in {
      display: none;
    }
  }
  @media(min-width: 768px) {
    text-align: ${({ align }) => align};
  }
`

function CountDown({
  date, align, alignSmall, fontSmall, fontLarge, smallWidth, largeWidth, inicialState: {
    days: init_days,
    hours: init_hours,
    mins: init_mins,
    seconds: init_seconds,
  },
  margin,
}) {
  const [days, setDays] = useState(init_days)
  const [hours, setHours] = useState(init_hours)
  const [mins, setMins] = useState(init_mins)
  const [seconds, setSeconds] = useState(init_seconds)

  const daysArr = days.split('')
  const hoursArr = hours.split('')
  const minsArr = mins.split('')
  const secondsArr = seconds.split('')
  useEffect(() => {
    const intervalo = setInterval(() => {
      const t = getCountDown(date)
      setDays(t.remainDays)
      setHours(t.remainHours)
      setMins(t.remainMinutes)
      setSeconds(t.remainSeconds)
    }, 1000)
    return () => {
      clearInterval(intervalo)
    }
  }, [date])

  return (
    <CountDownStyled
      align={align}
      alignSmall={alignSmall}
      fontSmall={fontSmall}
      fontLarge={fontLarge}
      smallWidth={smallWidth}
      largeWidth={largeWidth}
      className="countdow-numbers"
    >
      <div className="count">
        <span className="time">{daysArr[0]}</span>
        <span className="time">{daysArr[1]}</span>
        <span>d</span>
        <span className="separator">:</span>
      </div>
      <div className="count">
        <span className="time">{hoursArr[0]}</span>
        <span className="time">{hoursArr[1]}</span>
        <span>h</span>
        <span className="separator">:</span>
      </div>
      <div className="count">
        <span className="time">{minsArr[0]}</span>
        <span className="time">{minsArr[1]}</span>
        <span>m</span>
        <span className="separator">:</span>
      </div>
      <div className="count">
        <span className="time">{secondsArr[0]}</span>
        <span className="time">{secondsArr[1]}</span>
        <span className="separator">s</span>
      </div>
    </CountDownStyled>
  )
}

CountDown.defaultProps = {
  fontSmall: 'regularBold',
  fontLarge: 'healine6',
  smallWidth: '11px',
  largeWidth: '14px',
}

CountDown.propTypes = {
  fontSmall: PropTypes.string,
  fontLarge: PropTypes.string,
  smallWidth: PropTypes.string,
  largeWidth: PropTypes.string,
  inicialState: PropTypes.shape({
    days: PropTypes.string,
    hours: PropTypes.string,
    mins: PropTypes.string,
    seconds: PropTypes.string,
  }).isRequired,
}

function DiscountTimer({
  alignSmall, discount, color, align, dataCy, fontSmall, fontLarge, captionSmall, captionLarge, smallWidth, largeWidth, caption,
}) {
  const { diff, date } = useDiff(discount?.expires_at)

  if (!discount) return null

  const { status, expires_at } = discount

  if (status !== 'VALID') return null

  if (!expires_at) return null

  if (status === 'VALID' && !diff) {
    return (
      <Typography small={captionSmall} large={captionLarge} color={color} className="coupon-expiration-description">
        La oferta ha terminado
      </Typography>
    )
  }

  if (status === 'VALID' && diff) {
    const init_t = getCountDown(date)
    return (
      <DiscountTimerStyled align={align} alignSmall={alignSmall} color={color} data-cy={dataCy} className="discount-container">
        <Typography small={captionSmall} large={captionLarge} color={color} className="offer-ends-in coupon-expiration-description">
          {caption}
        </Typography>
        <CountDown
          date={date}
          align={align}
          alignSmall={alignSmall}
          fontSmall={fontSmall}
          fontLarge={fontLarge}
          smallWidth={smallWidth}
          largeWidth={largeWidth}
          inicialState={{
            days: init_t.remainDays,
            hours: init_t.remainHours,
            mins: init_t.remainMinutes,
            seconds: init_t.remainSeconds,
          }}
        />
      </DiscountTimerStyled>
    )
  }

  return null
}

DiscountTimer.defaultProps = {
  color: 'var(--invertedTurquoise)',
  fontSmall: 'regularBold',
  fontLarge: 'headline6',
  captionSmall: 'smallBold',
  captionLarge: 'p2Bold',
  smallWidth: '11px',
  largeWidth: '14px',
  dataCy: 'discount-timer',
  align: 'left',
  caption: 'La oferta termina en:',
}

DiscountTimer.propTypes = {
  dataCy: PropTypes.string,
  fontSmall: PropTypes.string,
  fontLarge: PropTypes.string,
  smallWidth: PropTypes.string,
  largeWidth: PropTypes.string,
  captionSmall: PropTypes.string,
  captionLarge: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  caption: PropTypes.string,
}

export default DiscountTimer
