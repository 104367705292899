import { useState, useEffect } from 'react'

function useViewportHeight() {
  const [height, setHeight] = useState('-webkit-fill-available')

  useEffect(() => {
    function handleResize() {
      const viewportHeight = window?.visualViewport?.viewportHeight || document?.documentElement?.clientHeight
      if (viewportHeight) setHeight(`${viewportHeight}px`)
    }
    handleResize()
    if (window?.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize)
    } else {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (window?.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize)
      } else {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return height
}

export default useViewportHeight
