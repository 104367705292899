import { useQueryClient, useQuery } from 'react-query'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { getCampaingService, getCustomCampaignService } from 'services/growth'
import handlePromise from 'utils/handle-promise'
import useSession from './session'

// eslint-disable-next-line import/prefer-default-export
export function useCampaign() {
  const { token } = useSession()
  const queryClient = useQueryClient()
  const { query: { course } } = useRouter()
  const query = useQuery(
    ['CAMPAING', token],
    () => getCampaingService({ token }),
    {
      placeholderData: {
        campaign_title: '',
        campaign_subtitle: '',
        campaign_url: '',
        campaign_img: '',
        campaign_coupon: '',
        campaign_all_site: false,
        coupon: null,
      },
      refetchOnWindowFocus: true,
      retryOnMoun: true,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
  const { isFetchedAfterMount } = query
  useEffect(() => {
    if (course && isFetchedAfterMount) {
      queryClient.setQueryData(
        ['CAMPAING', token],
        (campaing) => {
          return ({ ...campaing, campaing_all_site: true })
        },
      )
    }
  }, [isFetchedAfterMount])
  return { ...query, token }
}

export async function prefetchTotalUsers({ queryClient }) {
  const url = '/api/v1/stats/users/?page_size=1'
  await queryClient.prefetchQuery(
    'TOTAL_USERS',
    () => handlePromise(url, {
      method: 'GET',
      headers: {
        authorization: `Api-Key ${process.env.WIDGETS_API_KEY}`,
      },
    }),
  )
}

export function useTotalUsers() {
  const url = '/api/v1/stats/users/?page_size=1'
  return useQuery(
    'TOTAL_USERS',
    () => handlePromise(url, {
      method: 'GET',
      headers: {
        authorization: `Api-Key ${process.env.WIDGETS_API_KEY}`,
      },
    }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
    },
  )
}

export async function prefetchCustomCampaign({ token, queryClient }) {
  await queryClient.prefetchQuery(
    ['CUSTOM_CAMPAING', token],
    () => getCustomCampaignService({ token }),
  )
}

export function useCustomCampaign() {
  const { token } = useSession()
  return useQuery(
    ['CUSTOM_CAMPAING', token],
    () => getCustomCampaignService({ token }),
    {
      enabled: !!token,
      placeholderData: {
        coupon: null,
      },
    },
  )
}
