import { useQuery } from 'react-query'

function useSession() {
  const { data, isStale, isPlaceholderData } = useQuery(
    'SESSION',
    () => fetch('/api/session').then((res) => res.json()),
    {
      staleTime: Infinity,
      placeholderData: {
        token: '',
        error: false,
        profile: {},
      },
    },
  )
  return data ? { ...data, isStale } : {}
}

export default useSession
