import { useState, useEffect } from 'react'
import styled from 'styled-components'
import useDiff from 'hooks/use-diff'
import { useRouter } from 'next/router'
import { useCampaign } from 'querys/growth'

const SimpleCountDownSpacerStyled = styled.div`
  height: 104px;
  @media(min-width: 1024px) {
    height: 80px;
  }
`

const CountDownSpacerStyled = styled.div`
  height: 79px;
  @media(min-width: 1024px) {
    height: 79px;
  }
`

const CountDownEndedStyled = styled.div`
  height: 39px;
`

/* This component create the necesary space with a fixed position on movile */
function CountDownSpacer({ open }) {
  const { data, isError } = useCampaign()
  const { pathname } = useRouter()
  const [endDate, setEndDate] = useState(null)
  const { diff: diffInTime } = useDiff(endDate)

  useEffect(() => {
    setEndDate(data?.coupon?.expires_at)
  }, [data?.coupon])

  if (!data || isError) return null

  const {
    campaign_title: title,
    campaign_all_site: all,
    campaing_cupon,
    coupon,
  } = data
  const course = pathname === '/cursos/[course]'
  const courseInCuponCourses = coupon && coupon.courses && coupon.courses.find((item) => item.slug === course)
  const hasAccess = courseInCuponCourses?.has_access
  const couponStatus = coupon && coupon.status

  const visibleInThisPage = (!coupon && all) || (all && !hasAccess) || (!hasAccess)
  const diff = diffInTime || (couponStatus === 'VALID' && !endDate)

  if (!title || !visibleInThisPage) return null

  if (!campaing_cupon && open && course) {
    return (
      <SimpleCountDownSpacerStyled />
    )
  }

  if (open && diff && course) {
    return (
      <CountDownSpacerStyled />
    )
  }

  if (!diff && couponStatus === 'VALID' && course) {
    return (
      <CountDownEndedStyled />
    )
  }

  return null
}

export default CountDownSpacer
