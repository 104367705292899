import React from 'react'
import styled from 'styled-components'

export const Svg = styled.svg`
  width: ${({ width }) => width || '33px'};
  height: ${({ height }) => (height || '33px')};
  margin: ${({ margin }) => margin};
  cursor: ${({ cursor }) => (cursor ? 'pointer' : 'unset')};
  position: ${({ position }) => (position || 'static')};
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  path {
    fill: ${({ darkColor, color }) => (darkColor || color)};
  }
`
export const primary = '#3138D1'
export function Play({ color = primary, ...props }) {
  return (
    <Svg
      {...props}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 1.23978e-05C8.954 1.23978e-05 0 8.95401 0 20C0 31.046 8.954 40 20 40C31.046 40 40 31.046 40 20C40 8.95401 31.046 1.23978e-05 20 1.23978e-05ZM20 4.00001C28.822 4.00001 36 11.178 36 20C36 28.822 28.822 36 20 36C11.178 36 4 28.822 4 20C4 11.178 11.178 4.00001 20 4.00001ZM16 11V29L28 20L16 11Z"
        fill={color}
      />
    </Svg>
  )
}

export const playAnimation = `
  &:hover {
    .circle {
      fill: url(#paint0_linear);
      animation: rotate 3s infinite linear;
      transform-origin: center;
      stroke: none;
    }
    .triangle {
      filter: drop-shadow(0px 0px 5px white) blur(1px);
      stroke: none;
    }
    .blur {
      filter: url(#filter_blur);
      animation: rotate-3d 3s infinite linear;
      transform-origin: calc(50% + 7px) calc(50% + 7px);
      transform: translate(-7px,-7px);
    }
  }
  @keyframes rotate {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
  @keyframes rotate-3d {
    from {
      transform: translate(-7px,-7px) rotateZ(0deg);
    }
    to {
      transform: translate(-7px,-7px) rotateZ(360deg);
    }
  }
`

const PlayAnimatedStyled = styled(Svg)`
  overflow: visible;
  .blur {
    transform: translate(-6px, -6px);
    position: relative;
    z-index: -1;
  }
`

export function PlayAnimated({ ...props }) {
  return (
    <PlayAnimatedStyled
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="blur">
        <path fillRule="evenodd" clipRule="evenodd" d="M46 6C34.9619 6 24.9602 10.4809 17.7197 17.7214C10.4793 24.9619 6 34.9619 6 46C6 57.0381 10.4793 67.0381 17.7197 74.2786C24.9602 81.5191 34.9619 86 46 86C57.0381 86 67.0381 81.5191 74.2786 74.2786C81.5191 67.0381 86 57.0381 86 46C86 34.9619 81.5191 24.9619 74.2786 17.7214C67.0381 10.4809 57.0381 6 46 6ZM46 13.7108C54.9107 13.7108 62.9814 17.3286 68.8272 23.1728C74.6714 29.017 78.2892 37.0893 78.2892 46C78.2892 54.9107 74.6714 62.983 68.8272 68.8272C62.9814 74.6714 54.9107 78.2891 46 78.2891C37.0893 78.2891 29.017 74.6714 23.1728 68.8272C17.3286 62.983 13.7108 54.9107 13.7108 46C13.7108 37.0893 17.3286 29.017 23.1728 23.1728C29.017 17.3286 37.0893 13.7108 46 13.7108Z" fill="url(#paint0_linear)" />
      </g>
      <path
        className="triangle"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6691 18.1565C30.2928 17.3617 28.597 17.3614 27.2205 18.1562C25.8439 18.9509 24.996 20.4196 24.996 22.0087L24.996 59.3766C24.996 60.9662 25.8439 62.4348 27.2205 63.2295C28.597 64.024 30.2928 64.024 31.6691 63.2292L64.0273 44.5453C65.4036 43.7505 66.2515 42.2819 66.2515 40.6927C66.2515 39.1035 65.4036 37.6348 64.0273 36.8404L31.6691 18.1565Z"
        fill="white"
        stroke="#0803d266"
        />
      <path
        className="circle"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 0C28.9619 0 18.9602 4.48094 11.7197 11.7214C4.47925 18.9619 0 28.9619 0 40C0 51.0381 4.47925 61.0381 11.7197 68.2786C18.9602 75.5191 28.9619 80 40 80C51.0381 80 61.0381 75.5191 68.2786 68.2786C75.5191 61.0381 80 51.0381 80 40C80 28.9619 75.5191 18.9619 68.2786 11.7214C61.0381 4.48094 51.0381 0 40 0ZM40 7.71084C48.9107 7.71084 56.9814 11.3286 62.8272 17.1728C68.6714 23.017 72.2892 31.0893 72.2892 40C72.2892 48.9107 68.6714 56.983 62.8272 62.8272C56.9814 68.6714 48.9107 72.2891 40 72.2891C31.0893 72.2891 23.017 68.6714 17.1728 62.8272C11.3286 56.983 7.7108 48.9107 7.7108 40C7.7108 31.0893 11.3286 23.017 17.1728 17.1728C23.017 11.3286 31.0893 7.71084 40 7.71084Z"
        fill="white"
        stroke="#0803d266"
      />
      <defs>
        <filter id="filter_blur" x="0" y="0" width="92" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient id="paint0_linear" x1="40" y1="-27" x2="40" y2="80" gradientUnits="userSpaceOnUse">
          <stop offset="0.256589" stopColor="#0704FE" />
          <stop offset="1" stopColor="#FA057C" />
        </linearGradient>
      </defs>
    </PlayAnimatedStyled>
  )
}

export function Padlock({ color = primary, ...props }) {
  return (
    <Svg
      {...props}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C4.67619 0 2 2.67619 2 6V7C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7V6C14 2.67619 11.3238 0 8 0ZM8 2C10.2762 2 12 3.72381 12 6V7H4V6C4 3.72381 5.72381 2 8 2ZM8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12Z"
        fill={color}
      />
    </Svg>
  )
}

export function Success({ color = primary, ...props }) {
  return (
    <Svg
      {...props}
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.293 0.292969L6 11.5859L1.70703 7.29297L0.292969 8.70703L6 14.4141L18.707 1.70703L17.293 0.292969Z"
        fill={color}
      />
    </Svg>
  )
}
