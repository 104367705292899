import { useState, useEffect } from 'react'

function useLocation() {
  const [location, setLocation] = useState({})

  useEffect(() => {
    if (window) {
      setLocation(window.location)
    }
  }, [])
  return {
    ...location,
  }
}

export default useLocation
