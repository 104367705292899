import { useState, useEffect } from 'react'
import { useQueryClient, useQuery } from 'react-query'
import {
  getSubscriptionPlansService,
  getSubscriptionStatus,
} from 'services/subscriptions'
import { useRouter } from 'next/router'
import { useCountryCode } from 'querys/payment'
import useSession from './session'

const TEN_MINUTES = 1000 * 60 * 10
const FIVE_SECONDS = 1000 * 5

export async function prefetchSubscriptionPlans({
  queryClient, countryCode, token, coupon_code,
}) {
  const userToken = token || ''
  const countryKey = countryCode || 'MX'
  const couponCode = coupon_code || null
  await queryClient.prefetchQuery(
    ['GET_SUBSCRIPCION_PLANS', countryKey, userToken, couponCode],
    () => getSubscriptionPlansService({
      countryCode: countryKey,
      token: userToken,
      couponCode,
    }),
  )
}

export function useSubscriptionPlans(initialCoupon = 'ppp') {
  const router = useRouter()
  const { query: { coupon_code } } = router
  const [couponSaved, setCuponSaved] = useState(initialCoupon || null)
  const setCoupon = (code) => {
    const initial = sessionStorage.getItem('initalCoupon')
    if (!initial) {
      sessionStorage.setItem('initalCoupon', couponSaved)
    }

    sessionStorage.setItem('couponCode', code)
    setCuponSaved(code)
  }
  useEffect(() => {
    const existentCoupon = sessionStorage.getItem('couponCode')
    if (existentCoupon) {
      setCuponSaved(existentCoupon)
    }
    if (coupon_code) {
      setCuponSaved(coupon_code)
      sessionStorage.setItem('initalCoupon', coupon_code)
      sessionStorage.setItem('couponCode', coupon_code)
      // router.replace(router.pathname, window.location.pathname, { scroll: false, shallow: true })
    }
  }, [coupon_code])
  const queryClient = useQueryClient()
  const { token } = useSession()
  const { data: { countryCode } } = useCountryCode()
  const couponCode = coupon_code || couponSaved || null
  const query = useQuery(
    ['GET_SUBSCRIPCION_PLANS', countryCode, token, couponCode],
    () => getSubscriptionPlansService({ countryCode, token, couponCode }),
    {
      placeholderData: () => queryClient.getQueryData(['GET_SUBSCRIPCION_PLANS', 'MX', '', null]),
      staleTime: Infinity,
    },
  )
  return {
    ...query, setCoupon,
  }
}

export function useSubscriptionStatus({ product, recurring }) {
  const recurrency = recurring || ''
  const { token } = useSession()
  const query = useQuery(
    ['GET_SUBSCRIPCION_STATUS', token, product, recurring],
    () => getSubscriptionStatus({ token, product, recurring: recurrency }),
    {
      initialData: {
        is_active: false,
      },
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: !!token,
    },
  )
  return { ...query, token }
}
