import handlePromise from 'utils/handle-promise'

export async function registerNewErrorService({ payload }) {
  const url = '/api/v1/payments/error/'
  const data = await handlePromise(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  return data
}

export async function registerBillingDetails({ payload }) {
  const url = '/api/v1/payments/bot/'
  const data = await handlePromise(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      payload,
    }),
  })
  return data
}

export async function getCouponEnabledService() {
  const url = '/api/v1/coupons/enabled/'
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}

export async function getPaymentConfigService({ course_slug, countryCode }) {
  let url
  if (course_slug) {
    url = `/api/v1/payments/config/?country_code=${countryCode}&course_slug=${course_slug}`
  } else {
    url = `/api/v1/payments/config/?country_code=${countryCode}`
  }
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}

export async function getCouponStatusService({ code, courseSlug, countryCode }) {
  const url = `/api/v1/coupons/${code}/status/?course_slug=${courseSlug}&country_code=${countryCode}`
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}

const fetcher = (url) => fetch(url).then((res) => res.json())

export async function getCountryService() {
  try {
    const url = 'https://pro.ip-api.com/json?key=EpYM2JUElPKNPrj'
    const data = await fetcher(url)
    return data
  } catch (err) {
    // If an extension block ip-api, we can use our server
    // to get the ip from the req object
    const data = await fetcher('/api/location')
    return data
  }
}

export async function getPaymentCountryService({ ip }) {
  const url = `https://pro.ip-api.com/json/${ip}?key=EpYM2JUElPKNPrj`
  const data = await fetcher(url)
  return data
}

// TODO: RES
export async function getPaymentStatusService({
  course_slug, token, res = null, req = null, email,
}) {
  const emailQuery = `?email=${email}`
  const url = `/api/v1/courses/${course_slug}/payment-status/${email ? emailQuery : ''}`
  const data = await handlePromise(url, { headers: { Authorization: token } }, res, req)
  return data
}

export async function getFinancialPlansService({ amount, currency_code }) {
  const url = `/api/v1/financial-plans/?amount=${amount}&currency_code=${currency_code}`
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}
