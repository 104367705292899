/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from './index'

export function ArrowTop({ width, height, color, className }) {
  return (
    <Svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        width={width}
        height={height}
        d="M11.5703 7.99414L13.0703 6.49414L7 0.423828L0.929688 6.49414L2.42969 7.99414L7 3.42383L11.5703 7.99414Z"
        fill={color}
      />
    </Svg>
  )
}

ArrowTop.defaultProps = {
  width: '14px',
  height: '9px',
  color: '#3138D1',
}

ArrowTop.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}

export function ArrowBottom({ width, height, color, className }) {
  return (
    <Svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        width={width}
        height={height}
        d="M2.42969 0.5L0.929688 2L7 8.07031L13.0703 2L11.5703 0.5L7 5.07031L2.42969 0.5Z"
        fill={color}
      />
    </Svg>
  )
}

ArrowBottom.defaultProps = {
  width: '14px',
  height: '9px',
  color: '#3138D1',
}

ArrowBottom.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}

export function ArrowRigth({ width, height, color }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        width={width}
        height={height}
        d="M0.5 11.5703L2 13.0703L8.07031 7L2 0.929688L0.5 2.42969L5.07031 7L0.5 11.5703Z"
        fill={color}
      />
    </Svg>
  )
}

ArrowRigth.defaultProps = {
  width: '9px',
  height: '14px',
  color: '#3f69ff',
}

ArrowRigth.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}

export function ArrowLeft({ width, height, color }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        width={width}
        height={height}
        d="M8.5 2.42969L7 0.929687L0.929687 7L7 13.0703L8.5 11.5703L3.92969 7L8.5 2.42969Z"
        fill={color}
      />
    </Svg>
  )
}

ArrowLeft.defaultProps = {
  width: '9px',
  height: '14px',
  color: '#3f69ff',
}

ArrowLeft.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}

export function BigArrowRigth({ width, height, color }) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33341 6.66647H8.78008L5.52675 9.91981C5.26675 10.1798 5.26675 10.6065 5.52675 10.8665C5.78675 11.1265 6.20675 11.1265 6.46675 10.8665L10.8601 6.47314C11.1201 6.21314 11.1201 5.79314 10.8601 5.53314L6.47341 1.13314C6.21341 0.873141 5.79341 0.873141 5.53341 1.13314C5.27341 1.39314 5.27341 1.81314 5.53341 2.07314L8.78008 5.33314H1.33341C0.966748 5.33314 0.666748 5.63314 0.666748 5.99981C0.666748 6.36647 0.966748 6.66647 1.33341 6.66647Z"
        fill={color}
      />
    </Svg>
  )
}

BigArrowRigth.defaultProps = {
  width: '12',
  height: '12',
  color: '#0704FE',
}

BigArrowRigth.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
