import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { SecondaryButton } from 'common/button'
import { Typography } from 'common/typography'
import DiscountTimer from 'common/banner/discount-timer'
import { useCountryCode } from 'querys/payment'
import { useCustomCampaign } from 'querys/growth'
import randomIntFromInterval from 'utils/random-int'
import useSession from 'querys/session'
import { sendToDataLayer } from 'utils/push-to-datalayer'
import useLocation from 'hooks/use-location'

const FirstOfferStyled = styled.div`
  cursor: pointer;
  * {
    user-select: none;
  }
  display: none;
  font: var(--regular);
  padding: 8px;
  border-radius: 8px;
  background: ${({ dark }) => dark};
  color: var(--neutral110);
  .original-price {
    text-decoration: line-through;
  }
  .price-container {
    display: flex;
    gap: 16px;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
  .countdown-container {
    color: var(--neutral110);
  }
  .price {
    display: flex;
    align-items: center;
    color: #5F0088;
    gap: 8px;
  }
  img {
    display: none;
  }
  @media(min-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 8px 40px;
    border-radius: 0;
    .fist-offer-info {
      margin: 0;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
  @media(min-width: 1366px) {
    img {
      display: block;
    }
  }
`

const themes = [
  {
    id: 'yellow', light: '#FFDD66', dark: '#FFDD66', btn: 'var(--secondary)',
  },
  {
    id: 'orange-white', light: '#EB5442', dark: '#FFFFFF', btn: 'var(--neutral110)',
  },
  {
    id: 'purple', light: '#DFD3FD', dark: '#DFD3FD', btn: 'var(--neutral110)',
  },
]

const promos = [
  {
    id: 'banner-free-forever',
    message: 'Con nuestra suscripción todos los cursos y proyectos que apruebes son tuyos para siempre',
    img: '/images/emails/win-badge.png',
  },
  {
    id: 'banner-projects',
    message: 'Tenemos más de 100 proyectos para tu portafolio',
    img: '/images/emails/project-start-ilustration.png',
  },
  {
    id: 'banner-linkedin-certificates',
    message: 'Agrega tus certificados a Linkedin en un click',
    img: '/images/emails/certificates_ilustration.png',
  },
  {
    id: 'banner-ordered-plan',
    message: 'No adivines por donde empezar. Nuestro plan de estudios está completamente ordenado y completo',
    img: '/emails/project-feedback-ilustration.png',
  },
  {
    id: 'banner-how-and-why',
    message: 'Aprende el cómo y el porqué de cada línea de código',
    img: '/images/emails/project-revision-ilustration.png',
  },
  {
    id: 'banner-podium',
    message: 'Compite con otros estudiantes, gana puntos y obtén una recomendación personalizada para tu portafolio',
    img: '/images/emails/project-aproved-ilustration.png',
  },
  {
    id: 'banner-msi',
    message: 'Paga nuestra suscripción anual a 6 meses sin intereses',
    img: '/images/emails/project-aproved-ilustration.png',
  },
]

// - - - - - - - - - - - - - - -
//  W A R N I N G
// Before realize any changes in this file, please read the documentation related to
// the events and activatos created in Google Tag Manager
// https://www.notion.so/noterindas/Google-Tag-Manager-23b20003cea74a939d19c37c4829e973#28f5da7284e5447983bd0a61cf8178ec
// - - - - - - - - - - - - - - -

function FirstOffer() {
  const { pathname } = useLocation()
  const { profile: { id } } = useSession()
  const [promo, setPromo] = useState({ id: '', message: '' })
  const [theme, setTheme] = useState({ light: '', dark: '' })
  const { data: { countryCode } } = useCountryCode()
  const { data: customCampaign } = useCustomCampaign()

  const { coupon, total_lessons, reactivated } = customCampaign || {}

  const divider = countryCode === 'MX' ? 6 : 5
  const price = countryCode === 'MX' ? 2980 : 149
  const originalPrice = countryCode === 'MX' ? 6960 : 348
  const currency = countryCode === 'MX' ? 'MXN' : 'USD'

  useEffect(() => {
    if (coupon?.status === 'VALID' && total_lessons >= 6) {
      const promoIdx = randomIntFromInterval(0, divider)
      const themeIdx = promoIdx % 3
      const colors = themes[themeIdx]
      const item = promos[promoIdx]
      setTheme(colors)
      setPromo(item)
      sendToDataLayer({
        event: 'first-offer-banner',
        bannerVariant: item.id,
        bannerColor: colors.id,
        eventType: 'impresion',
        eventMoment: reactivated ? 'reactivation' : 'first',
        student_id: id,
      })
    }
  }, [pathname])

  const handleClick = () => {
    sendToDataLayer({
      event: 'first-offer-banner',
      bannerVariant: promo.id,
      bannerColor: theme.id,
      eventType: 'click',
      student_id: id,
    })
  }

  if (!promo?.id || !coupon) return null

  return (
    <Link href={`/comprar/anual?coupon_code=${coupon.code}`} passHref>
      <FirstOfferStyled
        light={theme.light}
        dark={theme.dark}
        id={reactivated ? `reactivated-${promo.id}` : promo.id}
        onClick={() => handleClick()}
      >
        <div className="fist-offer-info">
          {promo.img ? (
            <img src={promo.img} alt="ilustration" width="80" />
          ) : null}
          <div>
            <Typography font="regularBold">{promo.message}</Typography>
            <div className="price">
              <Typography font="p2Bold">
                  $
                {price}
                {' '}
                {currency}
              </Typography>
              <Typography font="p2Regular" className="original-price">
                  $
                {originalPrice}
                {' '}
                {currency}
              </Typography>
            </div>
          </div>
        </div>
        <div className="countdown-container">
          <DiscountTimer
            color="inherit"
            caption=""
            discount={coupon}
          />
        </div>
        <SecondaryButton
          fullWidth
          fitContent
          background={theme.btn}
        >
            Suscribirme
        </SecondaryButton>
      </FirstOfferStyled>
    </Link>
  )
}

export default FirstOffer
