import { useMutation, useQueryClient } from 'react-query'
import Cookies from 'universal-cookie'
import jwt from 'jsonwebtoken'
import router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import {
  createCredentialSession, createSessionWithGoogle, confirmAccountService, recoverPasswordService,
} from 'services/session'
import { sendToDataLayer } from 'utils/push-to-datalayer'

const cookies = new Cookies()

export async function logout(queryClient) {
  cookies.remove('user_token', { path: '/', expires: new Date(0) })
  cookies.remove('user_payload', { path: '/', expires: new Date(0) })
  cookies.remove('user_id', { path: '/', expires: new Date(0) })
  await queryClient.refetchQueries(['SESSION'])
  router.reload()
}

function createSession(user, queryClient) {
  const token = user.session.access_token
  const { exp: expiration } = jwt.decode(token)
  const sessionExpiration = new Date((expiration * 1000))
  const options = { expires: sessionExpiration, path: '/' }
  const sessionToken = `Bearer ${user.session.access_token}`
  cookies.set('user_token', sessionToken, options)
  cookies.set('user_payload', jwt.sign(user.profile, 'secret'), options)
  cookies.set('user_id', user.profile.id, options)
  queryClient.setQueryData('SESSION', () => ({
    token: sessionToken,
    expiration: sessionExpiration,
    profile: user.profile,
  }))
  const params = router.query
  if (params.next === '/proyectos') {
    router.push('/proyectos/todos')
  }
  if (params.next) {
    router.push(params.next)
  } else {
    router.push('/aprender')
  }
}

export function useLoginWithCredentials() {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => createCredentialSession(payload),
    {
      onMutate: () => {
        NProgress.start()
      },
      onError: (err) => {
        NProgress.done()
        queryClient.setQueryData('SESSION', () => ({ error: true }))
      },
      onSuccess: (user) => {
        NProgress.done()
        sendToDataLayer({
          event: 'login',
          method: 'email',
          user_Id: user.profile.id,
        })
        createSession(user, queryClient)
      },
    },
  )
}

export function useLoginWithGoogle() {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => createSessionWithGoogle(payload),
    {
      onMutate: () => {
        NProgress.start()
      },
      onError: (err) => {
        NProgress.done()
      },
      onSuccess: (user) => {
        NProgress.done()
        if (user) {
          createSession(user, queryClient)
        }
      },
    },
  )
}

export function useActivateAccount() {
  const { query: { next, activate_account: token } } = useRouter()
  return useMutation(
    (payload) => confirmAccountService({ token, payload }),
    {
      onSuccess: () => {
        if (next) {
          router.push(`/login?activated=true&next=${next}`)
        } else {
          router.push('/login&activated=true')
        }
      },
    },
  )
}

export function useForgetPassword() {
  return useMutation(
    (email) => recoverPasswordService(email)
  )
}
