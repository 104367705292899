import { useState, useEffect } from 'react'
import utcValues from 'utils/utc-values'

const useDiff = (endDate) => {
  const [diff, setDiff] = useState(true)
  const [date, setDate] = useState(utcValues(endDate))
  useEffect(() => {
    const newDate = utcValues(endDate)
    if (date !== newDate) {
      setDate(newDate)
    }
  }, [endDate])
  useEffect(() => {
    const today = new Date()
    if ((date) && (today > date)) {
      setDiff(false)
    } else if (!diff) {
      setDiff(true)
    }
    const interval = setInterval(() => {
      const now = new Date()
      if ((date) && (now > date)) {
        setDiff(false)
        clearInterval(interval)
      } else if (!diff) {
        setDiff(true)
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [date])
  return { diff, date }
}

export default useDiff
