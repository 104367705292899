/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
import styled from 'styled-components'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { ArrowBottom } from 'components/icons/arrow'
import useSession from 'querys/session'
import { signOut } from 'next-auth/react'
import { logout } from 'mutations/session'
import { useQueryClient } from 'react-query'
import { Typography } from './typography'
import Divider from './divider'

const UserStyled = styled.div`
  display: flex;
  flex: 1;
  user-select: none;
  cursor: pointer;
  color: var(--baseSecondary);
  .profile {
    top: 0;
    left: 55px;
    right: 0;
    box-sizing: border-box;
    font-size: 14px;
    min-width: 0;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .user-menu {
    padding: 24px;
    background: var(--neutral90);
    filter: drop-shadow(0px 2px 24px rgba(97, 112, 216, 0.4));
    font-family: var(--secondaryFont);
    font-size: 16px;
    display: grid;
    align-items: center;
    z-index: 1;
    position: absolute;
    visibility: hidden;
    top: 80%;
    right: 16px;
    box-sizing: border-box;
    width: 316px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    overflow: hidden;
    .menu-title {
      position: relative;
    }
    .menu-item.no-m {
      margin: 0;
    }
    .menu-item {
      display: flex;
      margin: 24px 0;
      align-items: center;
      gap: 8px;
    }
    .menu-item:hover .menu-title {
      color: var(--turquoise);
    }
  }
  img {
    border-radius: 50%;
  }
  .logout-check {
    display: none;
  }
  label svg {
    transition: 0.3s ease transform;
  }
  .logout-check {
    &:checked {
      ~ .user-menu{
        visibility: visible;
      }
      ~ label svg {
        transform: rotate(180deg);
      }
    }
  }
  .close-session {
    font: var(--p2Bold);
  }
  @media screen and (min-width: 768px) {
    .user-menu {
      right: 32px;
    }
  }
  @media screen and (min-width: 1024px) {
    .user-menu {
      right: 60px;
    }
    .profile {
      padding: 0;
      position: initial;
      font-size: 1rem;
      span {
        display: none;
      }
    }
    img {
      margin: 0;
    }
  }
`

function UserSession({ logoutId }) {
  const { profile: user } = useSession()

  const queryClient = useQueryClient()

  function handleLogOutClick() {
    logout(queryClient)
    signOut()
  }

  return (
    <UserStyled>
      <input type="checkbox" id={logoutId} className="logout-check" />
      <label className="profile" htmlFor={logoutId} data-cy="profile">
        <img src={user.photo || '/icons/icon-50x50.png'} alt={user.first_name} width="32" height="32" />
        <ArrowBottom color="var(--secondary)" width="14px" height="9px" />
      </label>
      <nav className="user-menu" aria-label="Menú del usuario">
        <ul>
          <li>
            <Link href="/estudiante" className="menu-item no-m" id="h-profile">
              <img width="32" height="32" src="/images/menu/profile.png" alt="Perfil" />
              <div>
                <Typography font="p2Bold" className="menu-title">
                  Mi Perfil
                </Typography>
                <Typography font="smallRegular">Accede y adminsitra tus datos.</Typography>
              </div>
            </Link>
          </li>
          <li>
            <Link href="/estudiante#certificados" scroll={false} className="menu-item" id="h-portfolio">
              <img width="32" height="32" src="/images/menu/badge.png" alt="Certificados Icon" />
              <div>
                <Typography font="p2Bold" className="menu-title">
                  Mis certificados
                </Typography>
                <Typography font="smallRegular">Añade tus certificados a Linkedin.</Typography>
              </div>
            </Link>
          </li>
          <li>
            <Link href="/estudiante/portafolio" className="menu-item" id="h-portfolio">
              <img width="32" height="32" src="/images/menu/file.png" alt="Certificados Icon" />
              <div>
                <Typography font="p2Bold" className="menu-title">
                  Mi portafolio
                </Typography>
                <Typography font="smallRegular">Administra tu portafolio.</Typography>
              </div>
            </Link>
          </li>
          {/* <li>
            <Link href="/aprender">
              <a className="menu-item">
                <img width="32" height="32" src="/images/menu/money.png" alt="Cursos" />
                <div>
                  <Typography font="p2Bold" className="menu-title">
                    Mis pagos
                  </Typography>
                  <Typography font="smallRegular">Administra tus pagos y subscripciones.</Typography>
                </div>
              </a>
            </Link>
          </li> */}
          <Divider stroke={1} color="var(--neutral70)" opacity="0.16" marginBottom={16} />
          <li onClick={handleLogOutClick} className="menu-item no-m close-session">
            <span className="menu-title">Cerrar Sesión</span>
          </li>
        </ul>
      </nav>
    </UserStyled>
  )
}

UserSession.defaultProps = {
  logoutId: 'logout',
}

UserSession.propTypes = {
  logoutId: PropTypes.string,
}

export default UserSession
